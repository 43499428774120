import { createSlice } from "@reduxjs/toolkit";
import { userSliceState } from "../../types";

const initialState: userSliceState = {
  email: null,
  token: null,
  id: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.id = action.payload.id;
    },
    removeUser(state) {
      state.email = null;
      state.token = null;
      state.id = '';
    }
  }
})

export const {setUser, removeUser} = userSlice.actions;
export default userSlice.reducer;